import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				adSetIds: [],
				adSetName: '',
				campaignIds: [],
				campaignName: '',
				channels: [],
				domainNames: '',
				date: [
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
				],
				groupType: '0'
			},
			tableData: [],
			q: {
				dateFrom: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				dateTo: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				groupType: '0'
			},
			namespace: '',
			fetchOnCreated: false,
			allChannels: [
				'all',
				'tiktok',
				'facebook'
			]
		}
	},
	created() {
		const {
			dateFrom,
			dateTo,
			adSetIds,
			adSetName,
			campaignIds,
			campaignName,
			channels,
			domainNames,
			groupType
		} = this.$route.query
		if (dateFrom && dateTo && /\d{4}-\d{2}-\d{2}/.test(dateFrom) && /\d{4}-\d{2}-\d{2}/.test(dateTo)) {
			this.queryForm.date = [dateFrom, dateTo]
			this.q.dateFrom = dateFrom
			this.q.dateTo = dateTo
		}
		if (groupType !== undefined && groupType.match(/^[0-1]$/) !== null) {
			this.queryForm.groupType = groupType
		}
		if (adSetIds !== undefined && adSetIds.match(/\d+/g) !== null) {
			this.queryForm.adSetIds = adSetIds.match(/\d+/g)
		}
		if (adSetName !== undefined) {
			this.queryForm.adSetName = adSetName
		}
		if (campaignIds !== undefined && campaignIds.match(/\d+/g) !== null) {
			this.queryForm.campaignIds = campaignIds.match(/\d+/g)
		}
		if (campaignName !== undefined) {
			this.queryForm.campaignName = campaignName
		}
		if (domainNames !== undefined && domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
			this.queryForm.domainNames = domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g).join(',')
		}
		if (channels !== undefined) {
			let _channels = channels.split(',').reduce((lhs, rhs) => {
				if (this.channels.includes(rhs)) {
					lhs.push(rhs)
				}
				return lhs
			}, [])
			if (_channels.length > 0) {
				this.queryForm.channels = _channels
			}
		}
		this.searchEvent()
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
					if (key === 'date') {
						const [dateFrom, dateTo] = queryForm[key]
						query['dateFrom'] = dateFrom
						query['dateTo'] = dateTo
					} 
					// else if (key === 'campaignIds') {
					// 	query['campaignIds'] = (queryForm['campaignIds'] || '').match(/\d+/g)
					// } else if (key === 'adSetIds') {
					// 	query['adSetIds'] = (queryForm['adSetIds'] || '').match(/\d+/g)
					// } 
					else if (key === 'domainNames') {
						query['domainNames'] = (queryForm['domainNames'] || '').match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g)
					} else {
						query[key] = queryForm[key];
					}
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent() {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		handleRawData(content) {
			this.tableData = content.map(v => {
				let profitRate = (Number(v.estimatedRevenue) - Number(v.cost)) / Number(v.cost)
				profitRate = isFinite(profitRate) ? profitRate : 0.00
				let roi =  Number(v.estimatedRevenue) / Number(v.cost)
				roi = isFinite(roi) ? roi : 0
				const createTime = (new Date(v.createTime)).getTime()
				const updateTime = (new Date(v.updateTime)).getTime()
				return {
					...v,
					profitRate,
					roi,
					createTime,
					updateTime
				}
			})
			this.doLayout()
		},
		doLayout() {},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/getList`, {
				params: {
					...this.q
				},
				trigger
			})
		},
		clickDownload(filename) {
			const startDate = Moment(Number(this.q.startDate)).format('YYYY-MM-DD')
			const endDate = Moment(Number(this.q.endDate)).format('YYYY-MM-DD')
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/exportData`,
				payload: {
					...this.q
				},
				title: `${filename}(${this.q.reportDateMin}~${this.q.reportDateMax})`,
				dispatch: this.$store.dispatch,
				responseType: 'blob'
			})
		},
		downloadTask() {
			const q = this.buildQueryForm(this.queryForm)
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/getList`,
				payload: {...q},
				title: `JSCX(${q.dateFrom}~${q.dateTo})`,
				dispatch: this.$store.dispatch,
				requestHandler:(payload, pagable) => {
					return {params: payload}
				},
				responseHandler:(content) => {
					return {
						content,
						totalPages: 1
					}
				}
			})
		}
	}
}