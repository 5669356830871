<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false" :picker-options="pickerOptions">
					</el-date-picker>
				</form-item>
				<form-item label="粒度" style="width:180px;">
					<el-select v-model="queryForm.groupType" size="mini" @change="resetAndReload" class="w-100">
						<el-option label="CAMPAIGN" value="0"></el-option>
						<el-option label="ADSET" value="1"></el-option>
					</el-select>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<el-button type="primary" @click="showFilter" size="mini">过 滤</el-button>
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
				</form-item>
			</template>
			<template #advanced v-if="false">
				<form-item label="AdsetIds">
					<el-select v-model="queryForm.adSetIds" filterable placeholder="请选择" size="mini" clearable multiple>
						<el-option v-for="item in resAdSetIds" :key="item.value" :label="item.value"
							:value="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="Adset Name" style="width:300px;">
					<el-autocomplete v-model="queryForm.adsetName" :fetch-suggestions="showAdsetNames" placeholder="请输入"
						size="mini" class="w-100"></el-autocomplete>
				</form-item>
				<form-item label="CampaignIds">
					<el-select v-model="queryForm.campaignIds" filterable placeholder="请选择" size="mini" clearable
						multiple>
						<el-option v-for="item in resCampaignIds" :key="item.value" :label="item.value"
							:value="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="Campaign Name" style="width:300px;">
					<el-autocomplete v-model="queryForm.campaignName" :fetch-suggestions="showCampaignNames"
						placeholder="请输入" size="mini" class="w-100"></el-autocomplete>
				</form-item>
				<form-item label="Channels">
					<el-select v-model="queryForm.channels" placeholder="请选择" multiple size="mini" clearable>
						<el-option v-for="channel in allChannels" :label="channel.toUpperCase()"
							:value="channel"></el-option>
					</el-select>
				</form-item>
				<form-item label="Domain Names">
					<el-input v-model="queryForm.domainNames" placeholder="请输入" size="mini" clearable></el-input>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
			<div style="padding-left:20px;"><small>{{updatedTime}}</small></div>
		</div>
		<data-table ref="dataTable" class="mt-3 w-100" :tableData="filterData" :tableColumns="filterTableColumns"
			:groupType="queryForm.groupType" :selectedRows="filter.selectedRows" :onRowClick="onRowClick"
			:dateFrom="q.dateFrom" :dateTo="q.dateTo" @sortChange="sortChange"></data-table>
		<el-drawer title="过滤" :visible.sync="drawer.filter" direction="rtl">
			<div style="padding: 0 20px;">
				<el-button type="primary" @click="applyFilter" size="mini">应 用</el-button>
				<div><small>Placements:</small></div>
				<div>
					<el-select v-model="filter.placements" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in placements" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>AdSetIds:</small></div>
				<div>
					<el-select v-model="filter.adSetIds" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in adSetIds" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>AdSetNames:</small></div>
				<div>
					<el-select v-model="filter.adSetNames" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in adSetNames" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>CampaignIds:</small></div>
				<div>
					<el-select v-model="filter.campaignIds" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in campaignIds" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>CampaignNames:</small></div>
				<div>
					<template v-if="filter.campaignNameFuzzy">
						<el-input v-model="filter.campaignName" size="mini" placeholder=""
							style="width:179px;"></el-input>
					</template>
					<template v-else>
						<el-select v-model="filter.campaignNames" placeholder="" size="mini" filterable multiple>
							<el-option v-for="item in campaignNames" :label="item" :value="item"
								:key="item"></el-option>
						</el-select>
					</template>
					<el-switch style="margin-left:10px;" v-model="filter.campaignNameFuzzy" active-text="模糊搜索"
						size="mini">
					</el-switch>
				</div>
				<div><small>DomainNames:</small></div>
				<div>
					<el-select v-model="filter.domainNames" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in domainNames" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>Channels:</small></div>
				<div>
					<el-select v-model="filter.channels" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in channels" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>Owners:</small></div>
				<div>
					<el-select v-model="filter.owners" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in owners" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>Direction:</small></div>
				<div>
					<el-select v-model="filter.directions" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in directions" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>Selected Rows:</small><el-switch v-model="filter.onlyShowSelectedRows"></el-switch></div>
			</div>
		</el-drawer>
		<table-columns-drawer :visible.sync="drawer.edit" :data="sortedTableColumns" :checkedKeys.sync="checkedKeys"
			:sortedKeys.sync="sortedKeys" :namespace="namespace" :updateColumns="updateColumns"></table-columns-drawer>
	</el-card>
</template>

<script>
	import DataTable from '@/components/jscx/data-table.vue'
	import config from './minixs/index.js'
	import Moment from 'moment'
	import TableColumnsDrawer from '@/components/jscx/table-columns-drawer.vue'
	export default {
		mixins: [config],
		components: {
			DataTable,
			TableColumnsDrawer
		},
		data() {
			return {
				timeout: null,
				sortedKeys: [],
				checkedKeys: [],
				namespace: 'jscx',
				pickerOptions: {
					disabledDate(time) {
						const today = new Date();
						const sixtyDaysAgo = today.getTime() - 60 * 24 * 60 * 60 * 1000; // 60 天前的时间戳
						return time.getTime() < sixtyDaysAgo || time.getTime() > today; // 禁用不在范围内的日期
					}
				},
				drawer: {
					filter: false,
					edit: false
				},
				resources: {
					adsets: [],
					campaigns: []
				},
				filter: {
					placements: [],
					adSetIds: [],
					adSetNames: [],
					campaignIds: [],
					campaignNames: [],
					domainNames: [],
					channels: [],
					owners: [],
					selectedRows: [],
					onlyShowSelectedRows: false,
					campaignNameFuzzy: false,
					campaignName: '',
					directions: []
				},
				tableColumns: [{
						label: "Campaign ID",
						prop: "campaignId",
						disabled: false,
						sortable: false
					},
					{
						label: "Campagin Name",
						prop: "campaignName",
						disabled: false,
						sortable: false
					},
					{
						label: "Adset ID",
						prop: "adSetId",
						disabled: false,
						sortable: false
					},
					{
						label: "Adset Name",
						prop: "adSetName",
						disabled: false,
						sortable: false
					},
					{
						label: "domain_name",
						prop: "domainName",
						disabled: false,
						sortable: false
					},
					{
						label: "budget",
						prop: "budget",
						disabled: false,
						summary: true,
						sortable: false
					},
					{
						label: "cost",
						prop: "cost",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: 'profit',
						prop: 'profit',
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: 'profitRate',
						prop: 'profitRate',
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "clicks",
						prop: "clicks",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "impressions",
						prop: "impressions",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "ctr",
						prop: "ctr",
						disabled: false,
						summary: false,
						sortable: true
					},
					{
						label: "conversion",
						prop: "conversion",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "bid",
						prop: "conversionBidPrice",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "cpc",
						prop: "cpc",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "ads-conversion",
						prop: "adsConversion",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "ads-cpc",
						prop: "adsCpc",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "epc",
						prop: "epc",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "f-epc",
						prop: "fepc",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "estimated_revenue",
						prop: "estimatedRevenue",
						disabled: false,
						summary: true,
						sortable: true
					},
					{
						label: "roi",
						prop: "roi",
						disabled: false,
						summary: true,
						sortable: true,
						checked: false
					},
					{
						label: 'Channel',
						prop: 'channel',
						disabled: true,
						sortable: false
					},
					{
						label: 'Placement',
						prop: 'placement',
						disabled: true,
						sortable: false
					},
					{
						label: 'Date',
						prop: 'date',
						disabled: true,
						sortable: false
					},
					{
						label: "owner",
						prop: "owner",
						disabled: true,
						sortable: false
					},
					{
						label: "create Time",
						prop: "createTime",
						disabled: false,
						sortable: true
					},
					{
						label: "update Time",
						prop: "updateTime",
						disabled: false,
						sortable: true
					},
					{
						label: "Direction",
						prop: "direction",
						disabled: false,
						sortable: false
					}
				],
				filterData: [],
				sortedTableColumns: [],
				filterTableColumns: []
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(`${this.namespace}_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.filter(v => v.checked !== false).map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(`${this.namespace}_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
			this.updateColumns()
		},
		mounted() {
			this.initResouces()
		},
		computed: {
			placements() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.placement && !lhs.includes(rhs.placement)) {
						lhs.push(rhs.placement)
					}
					return lhs
				}, [])
			},
			adSetIds() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.adSetId && !lhs.includes(rhs.adSetId)) {
						lhs.push(rhs.adSetId)
					}
					return lhs
				}, [])
			},
			adSetNames() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.adSetName && !lhs.includes(rhs.adSetName)) {
						lhs.push(rhs.adSetName)
					}
					return lhs
				}, [])
			},
			campaignIds() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.campaignId && !lhs.includes(rhs.campaignId)) {
						lhs.push(rhs.campaignId)
					}
					return lhs
				}, [])
			},
			campaignNames() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.campaignName && !lhs.includes(rhs.campaignName)) {
						lhs.push(rhs.campaignName)
					}
					return lhs
				}, [])
			},
			domainNames() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.domainName && !lhs.includes(rhs.domainName)) {
						lhs.push(rhs.domainName)
					}
					return lhs
				}, [])
			},
			channels() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.channel && !lhs.includes(rhs.channel)) {
						lhs.push(rhs.channel)
					}
					return lhs
				}, [])
			},
			owners() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.owner && !lhs.includes(rhs.owner)) {
						lhs.push(rhs.owner)
					}
					return lhs
				}, [])
			},
			directions() {
				return this.tableData.reduce((lhs, rhs) => {
					const direction = rhs.direction || 'Others'
					if (direction && !lhs.includes(direction)) {
						lhs.push(direction)
					}
					return lhs
				}, [])
			},
			resAdSetIds() {
				return this.resources.adsets.map(v => {
					return {
						value: v.id
					}
				})
			},
			resAdSetNames() {
				return this.resources.adsets.map(v => {
					return {
						value: v.name
					}
				})
			},
			resCampaignIds() {
				return this.resources.campaigns.map(v => {
					return {
						value: v.id
					}
				})
			},
			resCampaignNames() {
				return this.resources.campaigns.map(v => {
					return {
						value: v.name
					}
				})
			},
			updatedTime() {
				const updatedAt = this.tableData.reduce((max, current) => {
					// 如果 current.a 不是 null 并且比 max 大，更新 max
					return current.a !== null && current.updatedAt > max ? current.updatedAt : max;
				}, -Infinity);
				return updatedAt === -Infinity ? '' : '更新时间: ' + Moment(Number(updatedAt)).utc().format(
					'YYYY-MM-DDTHH:mm:ssZ')
			}
		},
		watch: {
			tableData(newValue, oldValue) {
				this.filter.adSetIds = []
				this.filter.adSetNames = []
				this.filter.campaignIds = []
				this.filter.campaignNames = []
				this.filter.domainNames = []
				this.filter.channels = []
				this.filter.owners = []
				this.filter.selectedRows = []
				this.filterData = [...newValue]
			},
			'queryForm.groupType'(newValue, oldValue) {
				this.$refs.dataTable.clearSort()
				this.$nextTick(() => {
					this.updateColumns()
				})
			}
		},
		methods: {
			updateColumns() {
				this.sortedTableColumns = this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
				const cols = this.queryForm.groupType === '0' ? this.sortedTableColumns.filter(v =>
					/(adset)|(bid)|(budget)/i.test(v.label) === false) : this.sortedTableColumns
				this.filterTableColumns = cols.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			applyFilter() {
				this.filterData = this.tableData.reduce((lhs, rhs) => {
					const res0 = this.filter.placements.length === 0 || this.filter.placements.length > 0 && this
						.filter.placements.includes(rhs.placement)
					const res1 = this.filter.adSetIds.length === 0 || this.filter.adSetIds.length > 0 && this
						.filter.adSetIds.includes(rhs.adSetId)
					const res2 = this.filter.adSetNames.length === 0 || this.filter.adSetNames.length > 0 && this
						.filter.adSetNames.includes(rhs.adSetName)
					const res3 = this.filter.campaignIds.length === 0 || this.filter.campaignIds.length > 0 && this
						.filter.campaignIds.includes(rhs.campaignId)
					const res4 = this.filter.campaignNameFuzzy ? (new RegExp(this.filter.campaignName, "ig")).test(
						rhs.campaignName) : (this.filter.campaignNames.length === 0 || this.filter
						.campaignNames.length > 0 && this.filter.campaignNames.includes(rhs.campaignName))
					const res5 = this.filter.domainNames.length === 0 || this.filter.domainNames.length > 0 && this
						.filter.domainNames.includes(rhs.domainName)
					const res6 = this.filter.channels.length === 0 || this.filter.channels.length > 0 && this
						.filter.channels.includes(rhs.channel)
					const res7 = this.filter.owners.length === 0 || this.filter.owners.length > 0 && this
						.filter.owners.includes(rhs.owner)
					const res8 = this.filter.onlyShowSelectedRows ? this.filter.selectedRows.indexOf(rhs) !== -1 :
						true
					const res9 = this.filter.directions.length === 0 || this.filter.directions.includes(rhs
						.direction || 'Others')
					if (res0 && res1 && res2 && res3 && res4 && res5 && res6 && res7 && res8 && res9) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
				this.drawer.filter = false
			},
			initResouces() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, resourceKey, retries) => {
					this.$store.dispatch(`${this.namespace}/${action}`)
						.then(res => {
							this.resources[resourceKey] = res; // 成功时设置资源
						})
						.catch(() => {
							if (retries > 0) {
								console.warn(`重试 ${resourceKey}，剩余重试次数: ${retries}`);
								fetchWithRetry(action, resourceKey, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};

				// 调用重试方法获取资源
				fetchWithRetry('getCampaigns', 'campaigns', maxRetries);
				fetchWithRetry('getAdsets', 'adsets', maxRetries);
			},
			doLayout() {
				this.$nextTick(() => {
					this.$refs.dataTable.doLayout()
				})
			},
			resetAndReload() {
				this.tableData = []
				this.searchEvent()
			},
			showFilter() {
				this.drawer.filter = true
			},
			showEdit() {
				this.drawer.edit = true
			},
			createStateFilter(queryString) {
				return (state) => {
					return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
				};
			},
			showAdsetNames(queryString, cb) {
				var names = this.resAdSetNames;
				var results = queryString ? names.filter(this.createStateFilter(queryString)) : names;
				cb(results);
			},
			showCampaignNames(queryString, cb) {
				var names = this.resCampaignNames;
				var results = queryString ? names.filter(this.createStateFilter(queryString)) : names;
				cb(results);
			},
			onRowClick(row, column, event) {
				const index = this.filter.selectedRows.indexOf(row)
				if (index === -1) {
					this.filter.selectedRows.push(row)
				} else {
					this.filter.selectedRows.splice(index, 1)
				}
			},
			sortChange({prop, order}) {
				console.log(prop, order)
			}
		}
	}
</script>

<style>
</style>